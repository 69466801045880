import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Result, Tabs } from 'antd'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import FileUpload from '../../../../../components/FileUpload/FileUpload'
import DataUpdate from '../dataUpdate'
import { useBackend } from '../../../../../services/backend'
import { useDocsURL } from '../../../../../utils/docs'

interface ApiResponse {
  httpCode: number
}

const VismaL7: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()

  const companyId = useSelector(contextCompanyIdSelector)
  const getCredentials = useBackend(`/l7/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)

  const [authorized, setAuthorized] = useState<boolean>(false)

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((response: ApiResponse) => {
        if (response?.httpCode === 200) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
        }
      })
      .catch(() => {
        setAuthorized(false)
      })
  }

  useEffect(getCredentialsHandler, [companyId])

  const items = [
    {
      label: t('integrationsPage:api'),
      key: '1',
      children: authorized ? (
        <>
          <Result status="success" title={t('integrationsPage:authenticated')} extra={<DataUpdate page="l7" />} />
        </>
      ) : (
        <a href={`${docsURL}manual/businesses/settings/company#visma-l7`} target="_blank" rel="noopener noreferrer">
          {t('header:manual')}
        </a>
      )
    },
    {
      label: t('integrationsPage:file-upload'),
      key: '2',
      disabled: authorized,
      children: (
        <FileUpload
          actionUrl={`${process.env.REACT_APP_INTEGRATION_URL}/l7/uploads/${companyId}`}
          taskRequestUrl="/l7/tasks/{companyId}"
          uploadText={t('integrationsPage:excel-info')}
        />
      )
    }
  ]

  return <Tabs defaultActiveKey="1" items={items} />
}

export default VismaL7
