import dayjs, { Dayjs } from 'dayjs'
import i18next from 'i18next'
import { arrayDownload, downloadMenuItems } from '../../utils/tableExport'
import {
  CustomExchangeRateSeries,
  StatementType,
  CustomExchangeRate,
  CustomExchangeRateSeriesWithCompany,
  SheetRow,
  reportNames
} from './types/exchangeRate'

/**
 * Parses exchange rate data from a sheet into a structured `CustomExchangeRateSeries`.
 * Combines new rates with existing company data and applies optional form updates.
 *
 * @param sheet - Array of rows from a sheet, where each row contains a `report` field
 *                (e.g., 'bs' or 'pl') and exchange rate values keyed by "month/year".
 * @param companyData - Existing exchange rate series data for a company. Required to
 *                      produce a result; if omitted, returns `null`.
 * @param formValues - Optional partial updates to the exchange rate series (e.g., `type` or `currency`).
 * @returns A `CustomExchangeRateSeries` object with updated rates, or `null` if `companyData` is not provided.
 *
 * @example
 * ```typescript
 * const sheet = [{ report: "pl", "3/2025": "1.23" }];
 * const companyData = { id: 1, groupId: "123", companyId: "456", type: "manual",
 *                       baseCurrency: "USD", currency: "EUR", rates: [] };
 * const result = parseRates(sheet, companyData);
 * // Returns updated series with a rate for March 2025
 * ```
 */
export const parseRates = (
  sheet: SheetRow[],
  companyData?: CustomExchangeRateSeries,
  formValues?: Partial<CustomExchangeRateSeries>
): CustomExchangeRateSeries | null => {
  if (!companyData) return null

  const series = sheet.flatMap(row => {
    const { report, ...values } = row
    return Object.entries(values)
      .map(([key, value]) => {
        const [month, year] = key.split('/').map(Number)
        const date = dayjs({ month: month - 1, year }).format('YYYY-MM-DD')
        return {
          statementType: report as StatementType,
          date,
          month,
          year,
          value: value ? parseFloat(value.replace(',', '.')) : null
        }
      })
      .filter(s => s.value !== null) as CustomExchangeRate[]
  })

  const rates: CustomExchangeRate[] = series.map(serie => {
    const found = companyData?.rates.find(
      ex => dayjs(ex.date).utc().isSame(serie.date, 'month') && serie.statementType === ex.statementType
    )
    return {
      ...serie,
      id: found?.id
    }
  })

  return { ...companyData, ...formValues, rates }
}

export const downloadMenu = (data: SheetRow[]) => {
  const handleMenuClick = ({ key }: any) => {
    const aoa = data.map(row => Object.values(row))
    switch (key) {
      case 'csv':
      case 'xlsx':
        arrayDownload(aoa, i18next.t('menu:/settings/company/group/exchangeRate'), key)
        break
      default:
        break
    }
  }

  const menuProps = {
    items: downloadMenuItems,
    onClick: handleMenuClick
  }

  return menuProps
}

/**
 * Converts exchange rate data to an Array of Arrays format for Excel export
 */
export function convertExchangeRatesToAOA(
  companyData?: CustomExchangeRateSeriesWithCompany,
  dates?: Dayjs[]
): (string | number)[][] {
  if (!companyData || !dates || dates.length === 0) {
    return [['No data available']]
  }

  // Create the header row with dates
  const headerRow = [`${companyData.currency}/${companyData.baseCurrency}`]
  dates.forEach(date => {
    headerRow.push(date.format('MM/YYYY')) // Format date as needed
  })

  // Initialize the result with header rows
  const result: (string | number)[][] = [headerRow]

  // Get unique statement types from the rates
  const statementTypes = Array.from(new Set(companyData.rates.map(rate => rate.statementType)))

  // For each statement type, create a row
  statementTypes.forEach(statementType => {
    const row: (string | number)[] = [
      i18next.t(`reportTypes:${reportNames[statementType as keyof typeof reportNames]}`)
    ]

    // For each date, find the matching rate
    dates.forEach(date => {
      const matchingRate = companyData.rates.find(
        rate => rate.statementType === statementType && rate.date === date.format('YYYY-MM-DD')
      )

      row.push(matchingRate ? parseFloat(matchingRate.value.toString()) : '')
    })

    result.push(row)
  })

  return result
}
